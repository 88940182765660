import { Injectable } from '@angular/core';
import { getEnvironment } from '../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsProvider {

  private static initialized = false;

  private static api_url: string;
  private static nacional_url: any;
  private static maps_url: any;
  private static vaucher_url: any;

  private static endpoints: any;

  constructor() {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
  }

  private static initialize() {
    const environment = getEnvironment();
    SettingsProvider.api_url = environment.apiUrl;
    SettingsProvider.maps_url = environment.mapsUrl;
    SettingsProvider.vaucher_url = environment.vaucherUrl;
    SettingsProvider.nacional_url = environment.nacionalUrl;
    SettingsProvider.endpoints = {
      authExternal: 'api/auth/social-media',
      login: 'organizations/auth-user/login',
      register: 'organizations/auth-user/register',
      logout: 'organizations/auth-user/logout',
      account: 'organizations/auth-user/me',
      test: 'https://madness.vouchers.prod.todotix.net/api/'
    };
    SettingsProvider.initialized = true;
  }

  static authRegister(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.register;
  }

  static authLogin(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.login;
  }

  static authExternal(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.authExternal;
  }

  static authLogout(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.logout;
  }

  static getAccount(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.account;
  }

  static getUrl(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + 'api/' + url;
  }

  static url(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + url;
  }

  static urlNacional(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.nacional_url + url;
  }

  static test(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return 'https://madness.vouchers.prod.todotix.net/api/report-reservation-by-auth/';
  }


  static urlVaucher(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.vaucher_url + url;
  }

  static urlMapService(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.maps_url + url;
  }


}
